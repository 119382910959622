import { React, useEffect, useState } from "react";
import AxiosInstance from '../Axios'
import { Container, Grid, Typography, Box, Slide } from '@mui/material';
import Footer from '../Footer/Footer';
import Loading from '../Misc/Loading';

const About = () => {
  const [aboutData, setAboutData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = () => {
      AxiosInstance.get(`api/about`, {
        params: {
          isactive: 'true'
        }
      }).then((res) => {
        setAboutData(res.data);
      }).finally(() => {
        setLoading(false);
      });
    };

    getData();
  }, []);

  return (
    <>
      <section id="aboutus" style={{ paddingTop: '56px' }}>
        {loading ?
          <Loading /> :
          <Container sx={{ py: { xs: 4, sm: 10 } }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                mx: 'auto',
              }}
            >
              <Typography component="h2" variant="h4" gutterBottom>
                About Us
              </Typography>
              <Typography component="h4" variant="h6" color="text.secondary" sx={{ mb: { xs: 2, sm: 4 } }}>
                Consider us as an integral part of your team, empowering you to confidently manage cloud security and compliance challenges. Our mission is clear: to deliver cloud security and compliance through automation, guaranteeing improved efficiency, accuracy, and regulatory adherence tailored to your specific needs.
              </Typography>
            </Box>
            <>
              {Array.isArray(aboutData) && aboutData.length > 0 ?
                aboutData.map((item, index) => (
                  <Grid
                    key={index}
                    container
                    spacing={2}
                    sx={{ py: { xs: 2, sm: 4 } }}
                  >
                    <Grid item xs={12} md={6} order={{ sm: 2, md: index % 2 === 0 ? 2 : 1 }} sx={{ alignSelf: 'center' }}>
                      <Slide direction={index % 2 === 0 ? 'left' : 'right'} in={true} timeout={800}>
                        <Box
                          component="div"
                          sx={{
                            alignSelf: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <img
                            src={`/assets/images/${item.image}`}
                            alt={item.title}
                            style={{ maxWidth: '100%', height: 'auto' }}
                          />
                        </Box>
                      </Slide>
                    </Grid>
                    <Grid item xs={12} md={6} order={{ sm: 1, md: index % 2 === 0 ? 1 : 2 }} sx={{ alignSelf: 'center' }}>
                      <Box sx={{ height: 'fit-content', width: '100%', background: 'none' }}>
                        <Typography variant="body2" sx={{ mb: 1, fontSize: { xs: 24, sm: 36 } }}>
                          {item.title}
                        </Typography>
                        <Typography
                          color="text.secondary"
                          variant="body2"
                          sx={{ my: 0.5, fontSize: { xs: 14, sm: 16 }, textAlign: 'justify' }}
                          dangerouslySetInnerHTML={{ __html: item.description }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                ))
                : null
              }
            </>
          </Container>
        }
      </section>
      <Footer />
    </>
  );
};

export default About;