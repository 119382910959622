import React, { useState, useEffect } from 'react';
import AxiosInstance from '../Axios'
import { Container, Grid, Typography, Box, Link, Slide } from '@mui/material';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { Link as RouterLink } from 'react-router-dom';
import Loading from '../Misc/Loading';

const Service = () => {
    const [serviceData, setServiceData] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchServiceDetail = async () => {
            try {
                const response = await AxiosInstance.get(`api/homepageservice/`);
                if (response.data) {
                    setServiceData(response.data);
                }
            } catch (err) {
                console.error("Error fetching data:", err);
            } finally {
                setLoading(false);
            }
        };

        fetchServiceDetail();
    }, []);

    return (
        <>
            {loading ?
                <Loading /> :
                <section id="services">
                    <Container sx={{ py: { xs: 4, sm: 8 } }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center',
                                width: { xs: '100%', md: '66%' },
                                mx: 'auto',
                            }}
                        >
                            <Typography component="h2" variant="h4" gutterBottom>
                                Services
                            </Typography>
                            <Typography variant="body1" color="text.secondary" sx={{ mb: { xs: 2, sm: 4 } }}>
                                We recognize the criticality of cloud security, governance and automation in the ever-evolving digital landscape.
                                We provide the following services as part of our comprehensive offering.
                            </Typography>
                        </Box>
                        <>
                            {serviceData.map((item, index) => (
                                <Grid
                                    key={index}
                                    container
                                    spacing={2}
                                    sx={{ py: { xs: 2, sm: 4 } }}
                                >
                                    <Grid item xs={12} md={6} order={{ sm: 1, md: index % 2 === 0 ? 1 : 2 }}>
                                        <Slide direction={index % 2 === 0 ? 'right' : 'left'} in={true} timeout={800}>
                                            <Box
                                                component="div"
                                                sx={{
                                                    alignSelf: 'center',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    overflow: 'hidden',
                                                    borderRadius: '10px',
                                                    outline: '1px solid',
                                                    outlineColor: 'rgba(191, 204, 217, 0.5)',
                                                    boxShadow: '0 0 12px 8px rgba(156, 204, 252, 0.2)',
                                                }}
                                            >
                                                <img
                                                    src={`${process.env.PUBLIC_URL}/assets/images/home/${item.feature_image}`}
                                                    alt={item.service}
                                                    style={{ maxWidth: '100%', height: 'auto' }}
                                                />
                                            </Box>
                                        </Slide>
                                    </Grid>
                                    <Grid item xs={12} md={6} order={{ sm: 2, md: index % 2 === 0 ? 2 : 1 }} sx={{ alignSelf: 'center' }}>
                                        <Box sx={{ height: 'fit-content', width: '100%', background: 'none' }}>
                                            <Typography variant="body2" sx={{ mb: 1, fontSize: { xs: 24, sm: 36 } }}>
                                                {item.service}
                                            </Typography>
                                            <Typography
                                                color="text.secondary"
                                                variant="body2"
                                                sx={{
                                                    '& p': {
                                                        margin: 0, // Remove margin from p tags
                                                        padding: 0, // Remove padding from p tags
                                                    },
                                                    fontSize: { xs: 14, sm: 16 },
                                                    textAlign: 'justify',
                                                    my: 0.5

                                                }}
                                                dangerouslySetInnerHTML={{ __html: item.description }}
                                            />
                                            <Link
                                                component={RouterLink}
                                                color="primary"
                                                variant="body2"
                                                fontWeight="bold"
                                                href="#"
                                                sx={{
                                                    display: 'inline-flex',
                                                    alignItems: 'center',
                                                    '& > svg': { transition: '0.2s' },
                                                    '&:hover > svg': { transform: 'translateX(2px)' },
                                                }}
                                                to={`/services/${item.service_url}`}
                                            >
                                                <span>Learn more</span>
                                                <ChevronRightRoundedIcon fontSize="small" sx={{ mt: '1px', ml: '2px' }} />
                                            </Link>
                                        </Box>
                                    </Grid>
                                </Grid>
                            ))}
                        </>
                    </Container>
                </section>
            }
        </>
    );
}

export default Service;