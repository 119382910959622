import React, { useEffect, useState } from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { Button, Typography, Box } from '@mui/material';
import ReactGA from 'react-ga4';

const initializeAnalytics = (trackingId) => {
    ReactGA.initialize(trackingId);
    ReactGA.send('pageview');
};

const CookieConsentDialog = () => {
    const [consentGiven, setConsentGiven] = useState(false);
    const [declined, setDeclined] = useState(false);

    useEffect(() => {
        const cookieConsent = Cookies.get('NubesSecuritasCookieConsent');
        if (cookieConsent === 'true') {
            setConsentGiven(true);
            initializeAnalytics('G-76EJBD3J15');
        } else if (cookieConsent === 'false') {
            setDeclined(true);
        }
    }, []);

    const handleAccept = () => {
        setConsentGiven(true);
        setDeclined(false);
        Cookies.set('NubesSecuritasCookieConsent', true, { expires: 365 });
        initializeAnalytics('G-76EJBD3J15');
    };

    const handleDecline = () => {
        setConsentGiven(true);
        setDeclined(true);
        Cookies.remove('_ga');
        Cookies.remove('_gat');
    };

    const acceptOnScroll = !declined;
    const acceptOnScrollPercentage = declined ? undefined : 35;

    if (consentGiven) {
        return null; // Render nothing if consent is already given
    }

    return (
        <CookieConsent
            location="bottom"
            buttonText="I accept"
            declineButtonText="I decline"
            cookieName="NubesSecuritasCookieConsent"
            style={{ background: "#15497d", color: "#ffffff" }}
            buttonStyle={{ color: "#4e503b", fontSize: "14px", backgroundColor: '#ffffff' }}
            declineButtonStyle={{ color: "#ffffff", fontSize: "14px", backgroundColor: '#f44336', marginRight: '10px' }}
            onAccept={handleAccept}
            onDecline={handleDecline}
            enableDeclineButton
            flipButtons
            ButtonComponent={Button}
            acceptOnScroll={acceptOnScroll}
            acceptOnScrollPercentage={acceptOnScrollPercentage}
            expires={365}
            overlay
        >
            <Typography variant="body2" color="inherit">
                This website uses cookies to enhance the user experience. By continuing to browse, you consent to our use of cookies.
                <Box component="span" sx={{ fontSize: "12px" }}>
                    Read more in our <a href="/privacy-policy" style={{ color: "#ffcc00" }}>Privacy Policy</a>.
                </Box>
            </Typography>
        </CookieConsent>
    );
};

export default CookieConsentDialog;
