import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/X';
import { styled } from '@mui/material/styles';
import { alpha } from '@mui/material';
import SnackbarContent from '@mui/material/SnackbarContent';
import AxiosInstance from '../Axios'

const Section = styled('section')(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    backgroundColor: theme.palette.mode === 'light' ? alpha('#f0f8ff', 0.7) : alpha('#9CCCFC', 0.1)
}));

// Styled components
const SuccessSnackbar = styled(SnackbarContent)(({ theme }) => ({
    backgroundColor: 'green',
    color: 'white',
}));

const ErrorSnackbar = styled(SnackbarContent)(({ theme }) => ({
    backgroundColor: 'red',
    color: 'white',
}));
function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" mt={1}>
            {'Copyright © '}
            <Link href="https://nubessecuritas.com/">NubesSecuritas&nbsp;</Link>
            {new Date().getFullYear()}
        </Typography>
    );
}

const Footer = () => {
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [open, setOpen] = useState(false);

    const handleSubscriber = async (e) => {
        e.preventDefault();
        const email = e.target.subscribe_email.value;

        // Client-side validation
        if (!email) {
            setError('Email is required.');
            setSuccess(null);
            setOpen(true);
            return;
        }

        // Simple email validation regex
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            setError('Please enter a valid email address.');
            setSuccess(null);
            setOpen(true);
            return;
        }
        
        try {
            const response = await AxiosInstance.post(`api/subscribe/`, { email });
            setSuccess(response.data.message);
            setError(null);
            e.target.reset(); // Clear subscribe email box
        } catch (error) {
            if (error.response) {
                setError(error.response.data.message  || 'An error occurred while subscribing.');
                setSuccess(null);
            } else {
                setError('An unexpected error occurred.');
                setSuccess(null);
            }
        } finally {
            setOpen(true); // Open Snackbar for both success and error messages
        }
    };

    const handleCloseSnackbar = () => {
        setOpen(false);
    };

    return (
        <Section>
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: { xs: 4, sm: 8 },
                    py: { xs: 8, sm: 10 },
                    textAlign: { sm: 'center', md: 'left' },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        width: '100%',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 4,
                            minWidth: { xs: '100%', sm: '60%' },
                        }}
                    >
                        <Box sx={{ width: { xs: '100%', sm: '60%' } }}>
                            <Typography variant="body2" fontWeight={600} gutterBottom>
                                Newsletter
                            </Typography>
                            <Typography variant="body2" color="text.secondary" mb={2}>
                                Subscribe to our newsletter for monthly updates and promotions.
                            </Typography>
                            <form onSubmit={handleSubscriber}>
                                <Stack direction="row" spacing={1} useFlexGap>
                                    <TextField
                                        id="subscribe_email"
                                        name="subscribe_email"
                                        hiddenLabel
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        aria-label="Enter your email address"
                                        placeholder="Your email address"
                                        inputProps={{
                                            autoComplete: 'off'
                                        }}
                                    />
                                    <Button variant="contained" type="submit" color="primary" sx={{ flexShrink: 0 }}>
                                        Subscribe
                                    </Button>
                                </Stack>
                                <Snackbar
                                        open={open}
                                        autoHideDuration={6000}
                                        onClose={handleCloseSnackbar}
                                    >
                                        {success ? (
                                            <SuccessSnackbar
                                                message={success}
                                                onClose={handleCloseSnackbar}
                                            />
                                        ) : (
                                            <ErrorSnackbar
                                                message={error}
                                                onClose={handleCloseSnackbar}
                                            />
                                        )}
                                    </Snackbar>
                            </form>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            display: { xs: 'none', sm: 'flex' },
                            flexDirection: 'column',
                            gap: 1,
                        }}
                    >
                        <Typography variant="body2" fontWeight={600}>
                            Our offerings
                        </Typography>
                        <Link color="text.secondary" href="/services/dashboard-for-monitoring">
                            Cloud Security Monitoring Dashboard
                        </Link>
                        <Link color="text.secondary" href="/services/cloud-security-consulting">
                            Cloud Security Consulting
                        </Link>
                        <Link color="text.secondary" href="/services/ad-security-assessment">
                            AD Security Assessment
                        </Link>
                        <Link color="text.secondary" href="/services/security-automation">
                            Securty Automation
                        </Link>
                        <Link color="text.secondary" href="/services/governance-oriented-landing-zones-architecture">
                            Governance-Oriented Landing Zones Architecture
                        </Link>
                        <Link color="text.secondary" href="/services/cloud-migration">
                            Cloud Migration
                        </Link>
                    </Box>
                    <Box
                        sx={{
                            display: { xs: 'none', sm: 'flex' },
                            flexDirection: 'column',
                            gap: 1,
                        }}
                    >
                        <Typography variant="body2" fontWeight={600}>
                            Company
                        </Typography>
                        <Link color="text.secondary" href="/about">
                            About us
                        </Link>
                        {/* <Link color="text.secondary" href="/careers">
                            Careers
                        </Link> */}
                        <Link color="text.secondary" href="/contact">
                            Contact
                        </Link>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        pt: { xs: 4, sm: 8 },
                        width: '100%',
                        borderTop: '1px solid',
                        borderColor: 'divider',
                    }}
                >
                    <div>
                        <Link color="text.secondary" href="/privacy-policy">
                            Privacy Policy
                        </Link>
                        <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
                            &nbsp;•&nbsp;
                        </Typography>
                        <Link color="text.secondary" href="/terms-of-use">
                            Terms of Service
                        </Link>
                        <Copyright />
                    </div>
                    <Stack
                        direction="row"
                        justifyContent="left"
                        spacing={1}
                        useFlexGap
                        sx={{
                            color: 'text.secondary',
                        }}
                    >
                        <IconButton
                            color="inherit"
                            href="https://twitter.com/nubessecuritas"
                            aria-label="X"
                            sx={{ alignSelf: 'center' }}
                        >
                            <TwitterIcon />
                        </IconButton>
                        <IconButton
                            color="inherit"
                            href="https://www.linkedin.com/company/nubessecuritas/"
                            aria-label="LinkedIn"
                            sx={{ alignSelf: 'center' }}
                        >
                            <LinkedInIcon />
                        </IconButton>
                    </Stack>
                </Box>
            </Container>
        </Section>
    );
}

export default Footer