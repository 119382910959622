import React, { useState, useEffect } from "react";
import FormLabel from '@mui/material/FormLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/system';
import './Contact.css';
import Footer from '../Footer/Footer';
import AxiosInstance from '../Axios'
import { Container, Grid, Typography, Paper, Button, Link, Snackbar } from '@mui/material';
import SnackbarContent from '@mui/material/SnackbarContent';

const defaultOfficeLocation = [{
    // define your default office location
    address: "<p>203 Fake St. Mountain View,</p>\r\n\r\n<p>2100 København Ø,</p>",
    country: "Denmark",
    office_title: "Head Office",
    email: "info@nubessecuritas.com",
    phone: "+45 23 23 23 53",
    working_hour: "Mon - Fri 8am - 5pm",
}];

const FormGrid = styled(Grid)(() => ({
    display: 'flex',
    flexDirection: 'column'
}));

// Styled components
const SuccessSnackbar = styled(SnackbarContent)(({ theme }) => ({
    backgroundColor: 'green',
    color: 'white',
}));

const ErrorSnackbar = styled(SnackbarContent)(({ theme }) => ({
    backgroundColor: 'red',
    color: 'white',
}));
const Contact = () => {
    const [officeLocations, setOfficeLocation] = useState([]);

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        country: '',
        company: '',
        message: ''
    });

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const fetchOfficeLocation = async () => {
            try {
                const response = await AxiosInstance.get(`api/companylocation/`);
                if (response.data) {
                    setOfficeLocation(response.data);
                } else {
                    setOfficeLocation(defaultOfficeLocation)
                }
            } catch (err) {
                setOfficeLocation(defaultOfficeLocation)
            }
        };

        fetchOfficeLocation();
    }, []);


    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await AxiosInstance.post(`api/contact/`, formData);
            setSuccess('Your message was sent successfully!');
            setError(null);
            setFormData({
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
                country: '',
                company: '',
                message: ''
            }); // Clear form after submission
        } catch (error) {
            if (error.response) {
                setError(error.response.data.error || 'An error occurred while sending the message.');
                setSuccess(null);
            } else {
                setError('An unexpected error occurred.');
                setSuccess(null);
            }
        } finally {
            setOpen(true); // Open Snackbar for both success and error messages
            await AxiosInstance.post(`/api/internalnotification/`, formData);
        }
    };

    const handleCloseSnackbar = () => {
        setOpen(false);
    };

    return (
        <>
            <section id="contact-section" style={{ paddingTop: '64px' }}>
                <Container sx={{ py: { xs: 2, sm: 4 } }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography component="h2" variant="h4" align="center">
                                Contact Us
                            </Typography>
                            <Typography variant="body1" align="center">
                                Contact us to learn more about how we can secure and automate your business.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <Paper elevation={2} className="custom-paper">
                                <Typography component="h5" variant="h4">
                                    Our Office
                                </Typography>
                                <br />
                                {officeLocations.map((contactData, index) => (
                                    <Grid key={index}>
                                        <Typography variant="h5" component="h6">
                                            {contactData.country} – {contactData.office_title}
                                        </Typography><br /><Typography variant="body1" fontWeight="bold">
                                            Address
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                '& p': {
                                                    margin: 0, // Remove margin from p tags
                                                    padding: 0, // Remove padding from p tags
                                                },
                                            }}
                                            dangerouslySetInnerHTML={{ __html: `${contactData.address}<p>${contactData.country}</p>` }}
                                        />
                                        <br /><Typography variant="body1" fontWeight="bold">
                                            Phone
                                        </Typography><Typography variant="body1">
                                            {contactData.phone}
                                        </Typography><br /><Typography variant="body1" fontWeight="bold">
                                            Email Address
                                        </Typography><Typography variant="body1">
                                            <Link href="#" color="inherit">
                                                {contactData.email}
                                            </Link>
                                        </Typography><br /><Typography variant="body1" fontWeight="bold">
                                            Office Opening Hours
                                        </Typography><Typography variant="body1">
                                            {contactData.working_hour}
                                        </Typography>
                                    </Grid>
                                ))}
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Paper elevation={2} className="custom-paper">
                                <Typography component="h5" variant="h4">
                                    We'd love to hear from you!
                                </Typography>
                                <br />
                                <Typography variant="body1">
                                    If you have any questions or inquiries, please feel free to reach out to us using the form below. We'll get back to you as soon as possible.
                                </Typography>
                                <br />
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={3}>
                                        <FormGrid item xs={12} md={6}>
                                            <FormLabel htmlFor="first_name" required>
                                                First name
                                            </FormLabel>
                                            <OutlinedInput
                                                id="first_name"
                                                name="first_name"
                                                type="name"
                                                placeholder="First name"
                                                autoComplete="first name"
                                                required
                                                className="form-input-field"
                                                value={formData.first_name}
                                                onChange={handleChange}
                                            />
                                        </FormGrid>
                                        <FormGrid item xs={12} md={6}>
                                            <FormLabel htmlFor="last_name" required>
                                                Last name
                                            </FormLabel>
                                            <OutlinedInput
                                                id="last_name"
                                                name="last_name"
                                                type="last-name"
                                                placeholder="Last name"
                                                autoComplete="last name"
                                                required
                                                className="form-input-field"
                                                value={formData.last_name}
                                                onChange={handleChange}
                                            />
                                        </FormGrid>
                                        <FormGrid item xs={12} md={6}>
                                            <FormLabel htmlFor="email" required>Email</FormLabel>
                                            <OutlinedInput
                                                id="email"
                                                name="email"
                                                type="email"
                                                placeholder="Email"
                                                autoComplete="email"
                                                required
                                                className="form-input-field"
                                                value={formData.email}
                                                onChange={handleChange}
                                            />
                                        </FormGrid>
                                        <FormGrid item xs={12} md={6}>
                                            <FormLabel htmlFor="phone" required>
                                                Phone Number
                                            </FormLabel>
                                            <OutlinedInput
                                                id="phone"
                                                name="phone"
                                                type="tel"
                                                placeholder=""
                                                autoComplete="phone-number"
                                                className="form-input-field"
                                                required
                                                value={formData.phone}
                                                onChange={handleChange}
                                            />
                                        </FormGrid>
                                        <FormGrid item xs={12} md={6}>
                                            <FormLabel htmlFor="country" required>
                                                Country
                                            </FormLabel>
                                            <OutlinedInput
                                                id="country"
                                                name="country"
                                                type="country"
                                                placeholder="Denmark"
                                                autoComplete="country"
                                                required
                                                className="form-input-field"
                                                value={formData.country}
                                                onChange={handleChange}
                                            />
                                        </FormGrid>
                                        <FormGrid item xs={12} md={6}>
                                            <FormLabel htmlFor="company" required>
                                                Company
                                            </FormLabel>
                                            <OutlinedInput
                                                id="company"
                                                name="company"
                                                type="text"
                                                placeholder="Name of Company"
                                                autoComplete="name of company"
                                                required
                                                className="form-input-field"
                                                value={formData.company}
                                                onChange={handleChange}
                                            />
                                        </FormGrid>
                                        <FormGrid item xs={12}>
                                            <FormLabel htmlFor="message" required>
                                                Message
                                            </FormLabel>
                                            <OutlinedInput
                                                fullWidth
                                                id="message"
                                                name="message"
                                                label="Message"
                                                multiline
                                                rows={7} // Minimum number of rows
                                                placeholder="Write your message here..."
                                                style={{ width: '100%', resize: 'vertical' }} // Allow vertical resizing
                                                variant="outlined"
                                                required
                                                value={formData.message}
                                                onChange={handleChange}
                                            />
                                        </FormGrid>
                                        <FormGrid item xs={12}>
                                            <Typography variant="caption">
                                                NubesSecuritas needs the contact information you provide to us to contact you. You may unsubscribe from these communications at any time. For information on how to unsubscribe, as well as our privacy practices and commitment to protecting your privacy, please review our <Link href="#" color="inherit">
                                                    Privacy Policy
                                                </Link>.
                                            </Typography>
                                        </FormGrid>
                                        <FormGrid item xs={12}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                size="large"
                                                fullWidth
                                            >
                                                Send Message
                                            </Button>
                                        </FormGrid>
                                    </Grid>
                                    <Snackbar
                                        open={open}
                                        autoHideDuration={6000}
                                        onClose={handleCloseSnackbar}
                                    >
                                        {success ? (
                                            <SuccessSnackbar
                                                message={success}
                                                onClose={handleCloseSnackbar}
                                            />
                                        ) : (
                                            <ErrorSnackbar
                                                message={error}
                                                onClose={handleCloseSnackbar}
                                            />
                                        )}
                                    </Snackbar>
                                </form>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </section>
            <Footer />
        </>
    );
}

export default Contact
