import React from 'react';
import { Box, Typography, Link, Grid } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import './NotFound.css'

function NotFound() {
    return (
        <Grid id="notfound" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Box className="notfound" sx={{ textAlign: 'center' }}>
                <Box className="notfound-404" sx={{ mb: 2 }}>
                    <Typography variant="h1" component="h1" sx={{ fontSize: '10rem', fontWeight: 'bold' }}>
                        404
                    </Typography>
                    <Typography variant="h2" component="h2" sx={{ fontSize: '2rem', fontWeight: 'bold' }}>
                        Page not found
                    </Typography>
                </Box>
                <Link component={RouterLink} to="/home" sx={{ textDecoration: 'none', fontSize: '1rem', color: 'primary.main' }}>
                    Homepage
                </Link>
            </Box>
        </Grid>
    );
}

export default NotFound;