import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import AxiosInstance from '../Axios'
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar'
import { styled } from '@mui/material/styles';
import SnackbarContent from '@mui/material/SnackbarContent';
import Footer from '../Footer/Footer';

// Styled components
const SuccessSnackbar = styled(SnackbarContent)(({ theme }) => ({
    backgroundColor: 'green',
    color: 'white',
}));

const ErrorSnackbar = styled(SnackbarContent)(({ theme }) => ({
    backgroundColor: 'red',
    color: 'white',
}));

const Unsubscribe = () => {

    const [searchParams] = useSearchParams();
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const handleUnsubscribe = async (event) => {
        event.preventDefault(); // Prevent the default form submission
        const token = searchParams.get('token'); // Extract the 'token' parameter from the URL
        if (token) {
            try {
                const response = await AxiosInstance.post('api/unsubscribe/', { unsubscribe_token: token });
                setSuccess(response.data.message);
                setError(null);
                // Redirect to home page on success
                navigate('/');
            } catch (error) {
                if (error.response) {
                    setError(error.response.data.message || 'An error occurred while subscribing.');
                    setSuccess(null);
                } else {
                    setError('An error occurred while unsubscribing. Please try again.');
                    setSuccess(null);
                }

            } finally {
                setOpen(true); // Open Snackbar for both success and error messages
            }
        } else {
            setError('Invalid unsubscribe link.');
            setSuccess(null);
            setOpen(true);
        }
    };

    const handleCloseSnackbar = () => {
        setOpen(false);
    };

    return (
        <>
            <section id="unsubscribe-section" style={{ paddingTop: '64px' }}>
                <Container sx={{ py: { xs: 4, sm: 6 }, maxWidth: 'lg' }}>
                    <Grid container spacing={3} justifyContent="center">
                        <Grid item xs={12} sm={8} md={6}>
                            <Typography
                                component="h2"
                                variant="h4"
                                align="center"
                                sx={{ mb: 3, fontWeight: 'bold', color: 'primary.main' }}
                            >
                                Unsubscribe
                            </Typography>
                            <Paper elevation={3} sx={{ p: 3, borderRadius: '12px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <Typography
                                    component="h5"
                                    variant="h5"
                                    sx={{ mb: 2, fontWeight: 'medium', color: 'text.primary' }}
                                >
                                    We noticed you're thinking of leaving us. Are you sure you want to unsubscribe?
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{ mb: 3, color: 'text.secondary', lineHeight: 1.6 }}
                                >
                                    We truly value your presence and would hate to see you go. We’d love to keep sharing our exciting updates and exclusive content with you.
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{ mb: 3, color: 'text.secondary', lineHeight: 1.6 }}
                                >
                                    But if you’re set on leaving, you can click the button below to unsubscribe. Just remember, you’re always welcome back whenever you’re ready to reconnect!
                                </Typography>
                                <form onSubmit={handleUnsubscribe}>
                                    <Stack direction="row" spacing={2} justifyContent="center">
                                        <Button
                                            variant="contained"
                                            type="submit"
                                            color="primary"
                                            sx={{ borderRadius: '8px', padding: '10px 20px', textTransform: 'uppercase' }}
                                        >
                                            Unsubscribe
                                        </Button>
                                    </Stack>
                                    <Typography
                                        variant="body2"
                                        align="center"
                                        sx={{ mt: 3, color: 'text.secondary' }}
                                    >
                                        Thank you for being part of our community, and we hope to see you again soon.
                                    </Typography>
                                    <Snackbar
                                        open={open}
                                        autoHideDuration={6000}
                                        onClose={handleCloseSnackbar}
                                        sx={{ mt: 2 }}
                                    >
                                        {success ? (
                                            <SuccessSnackbar
                                                message={success}
                                                onClose={handleCloseSnackbar}
                                            />
                                        ) : (
                                            <ErrorSnackbar
                                                message={error}
                                                onClose={handleCloseSnackbar}
                                            />
                                        )}
                                    </Snackbar>
                                </form>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </section>
            <Footer />
        </>
    );
}

export default Unsubscribe