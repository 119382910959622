import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Paper, Typography, Button, Grid, IconButton } from '@mui/material';
import './Home.css'; // Import your CSS file for styling
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Stack from '@mui/material/Stack';

const Banner = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const banners = [
        {
            imageUrl: 'assets/images/banner/banner_home_main.jpg',
            title: 'Enhance Your Cloud Security',
            description: 'Establish and implement secure configurations in the cloud based on industry best practices for enhanced security.',
            link: '/contact',
        },
    ];

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide === banners.length - 1 ? 0 : prevSlide + 1));
        }, 5000); // Change slide every 5000ms (5 seconds)

        return () => clearInterval(intervalId);
    }, [currentSlide, banners.length]);

    const handleNext = () => {
        setCurrentSlide((prevSlide) => (prevSlide === banners.length - 1 ? 0 : prevSlide + 1));
    };

    const handlePrev = () => {
        setCurrentSlide((prevSlide) => (prevSlide === 0 ? banners.length - 1 : prevSlide - 1));
    };

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
                <div className="banner-carousel-container">
                    <Paper>
                        <div className="banner-image" style={{ backgroundImage: `url(${banners[currentSlide].imageUrl})` }}>
                            <div className="banner-content">
                                <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '100%' } }}>
                                    <Typography
                                        component="span"
                                        variant="h1"
                                        sx={{
                                            fontSize: 'clamp(3rem, 10vw, 3.5rem)',
                                            alignSelf: 'center',
                                            textAlign: 'center',
                                            fontWeight: '500',
                                            color: (theme) =>
                                                theme.palette.mode === 'light' ? '#fff' : '#fff',
                                        }}
                                    >
                                        {banners[currentSlide].title}
                                    </Typography>
                                    <Typography
                                        textAlign="center"
                                        color="#fff"
                                        sx={{ alignSelf: 'center', width: { sm: '100%', md: '80%' }, fontSize: 'clamp(1rem, 10vw, 1.5rem)', }}
                                    >
                                        {banners[currentSlide].description}
                                    </Typography>
                                    <Stack
                                        direction={{ xs: 'column', sm: 'row' }}
                                        alignSelf="center"
                                        spacing={1}
                                        useFlexGap
                                        sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
                                    >
                                        <Button variant="contained" color="primary" component={RouterLink}  to={banners[currentSlide].link} sx={{ lineHeight: '2' }}>
                                            Contact
                                        </Button>
                                    </Stack>
                                </Stack>
                            </div>
                            <div className="nav-icons">
                                {banners.length > 1 && ( // Conditionally render the IconButton elements
                                    <>
                                        <IconButton className="prev-button" onClick={handlePrev}><ChevronLeftIcon /></IconButton>
                                        <IconButton className="next-button" onClick={handleNext}><ChevronRightIcon /></IconButton>
                                    </>
                                )}
                            </div>
                        </div>
                    </Paper>
                </div>
            </Grid>
        </Grid>
    );
};

export default Banner;
