import axios from 'axios'

const baseUrl = process.env.REACT_APP_BASE_URL
const AxiosInstance = axios.create({
    baseURL: baseUrl,
    timeout: 50000,
    headers: {
        "Content-Type": "application/json",
        accept: "application/json"
    }
})
// Add a response interceptor
AxiosInstance.interceptors.response.use(
    (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        return response;
    },
    (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        console.error('Error in Axios request:', error);
        // Customize the error message
        if (error.message === 'Network Error') {
            return Promise.reject('Network Error: Please check your internet connection.');
        }
        return Promise.reject(error);
    }
);

export default AxiosInstance;