import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const logoStyle = {
    width: '240px',
    height: 'auto',
    cursor: 'pointer',
    verticalAlign: 'middle',
};

function NavBar({ mode }) {
    const [open, setOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const location = useLocation();
    const path = location.pathname;

    const checkScrollConditions = () => {
        const bannerElement = document.querySelector('.banner-carousel-container');
        const pageHeight = document.documentElement.scrollHeight;
        const viewportHeight = window.innerHeight;

        if (!bannerElement || pageHeight <= viewportHeight || window.scrollY > 0.60 * window.innerHeight) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            checkScrollConditions();
        };

        checkScrollConditions();
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [location]);

    return (
        <div>
            <AppBar
                position="fixed"
                sx={{
                    boxShadow: 0,
                    bgcolor: isScrolled ? 'rgba(255, 255, 255, 0.4)' : 'transparent',
                    backdropFilter: isScrolled ? 'blur(24px)' : 'none',
                    transition: 'background-color 0.3s ease, backdrop-filter 0.3s ease',
                }}
            >
                <div maxwidth="lg">
                    <Toolbar
                        variant="regular"
                        sx={(theme) => ({
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            flexShrink: 0,
                            bgcolor: isScrolled
                                ? theme.palette.mode === 'light'
                                    ? 'rgba(255, 255, 255, 0.4)'
                                    : 'rgba(0, 0, 0, 0.4)'
                                : 'transparent',
                            backdropFilter: isScrolled ? 'blur(24px)' : 'none',
                            maxHeight: 40,
                            border: isScrolled ? '1px solid' : 'none',
                            borderColor: isScrolled ? 'divider' : 'none',
                            boxShadow: isScrolled
                                ? theme.palette.mode === 'light'
                                    ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                                    : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)'
                                : 'none',
                            transition: 'background-color 0.3s ease, backdrop-filter 0.3s ease',
                            '& .MuiMenuItem-root': {
                                py: '6px',
                                px: '12px',
                                borderRadius: '10px',
                                transition: 'background-color 0.3s ease',
                            },
                        })}
                    >
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: 'flex',
                                alignItems: 'center',
                                ml: '-18px',
                                px: 0,
                            }}
                        >
                            <Link to="/home">
                                <img
                                    src={isScrolled ? '/nubessecuritas-logo.png' : '/nubessecuritas-logo_white.png'}
                                    style={logoStyle}
                                    alt="NubesSecuritas"
                                />
                            </Link>
                        </Box>
                        <Box
                            sx={{
                                display: { xs: 'none', md: 'flex' },
                                gap: 0.5,
                                alignItems: 'center',
                            }}
                        >
                            <MenuItem component={Link} to="/about" selected={path === '/about'} sx={{ py: '6px', px: '12px' }}>
                                <Typography variant="body2" color={isScrolled ? 'text.primary' : "#fff"} sx={{ fontSize: '17px' }}>
                                    About
                                </Typography>
                            </MenuItem>
                            <MenuItem
                                component={Link} to="/services" selected={path === '/services'}
                                sx={{ py: '6px', px: '12px' }}
                            >
                                <Typography variant="body2" color={isScrolled ? 'text.primary' : "#fff"} sx={{ fontSize: '17px' }}>
                                    Services
                                </Typography>
                            </MenuItem>
                            <MenuItem
                                component={Link} to="/contact" selected={path === '/contact'}
                                sx={{ py: '6px', px: '12px' }}
                            >
                                <Typography variant="body2" color={isScrolled ? 'text.primary' : "#fff"} sx={{ fontSize: '17px' }}>
                                    Contact
                                </Typography>
                            </MenuItem>
                        </Box>
                        <Box sx={{ display: { sm: '', md: 'none' } }}>
                            <Button
                                variant="text"
                                color="primary"
                                aria-label="menu"
                                onClick={toggleDrawer(true)}
                                sx={{ minWidth: '30px', p: '4px' }}
                            >
                                <MenuIcon />
                            </Button>
                            <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                                <Box
                                    sx={{
                                        minWidth: '60dvw',
                                        p: 2,
                                        backgroundColor: 'background.paper',
                                        flexGrow: 1,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'end',
                                            flexGrow: 1,
                                        }}
                                    >
                                        <Button
                                            variant="text"
                                            color="primary"
                                            aria-label="close menu"
                                            onClick={toggleDrawer(false)}
                                            sx={{ minWidth: '30px', p: '4px' }}
                                        >
                                            <CloseIcon />
                                        </Button>
                                    </Box>
                                    <MenuItem component={Link} to="/about">
                                        About
                                    </MenuItem>
                                    <MenuItem component={Link} to="/services">
                                        Services
                                    </MenuItem>
                                    <MenuItem component={Link} to="/contact">
                                        Contact
                                    </MenuItem>
                                    <Divider />
                                </Box>
                            </Drawer>
                        </Box>
                    </Toolbar>
                </div>
            </AppBar>
        </div>
    );
}

NavBar.propTypes = {
    mode: PropTypes.oneOf(['dark', 'light']).isRequired,
};

export default NavBar;
