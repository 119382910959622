import React, { useState, useEffect } from "react";
import Footer from '../Footer/Footer';
import AxiosInstance from '../Axios'
import { Container, Grid, Typography, Paper } from '@mui/material';
import NotFound from '../404/NotFound';
import Loading from '../Misc/Loading';


const PrivacyPolicy = () => {
    const [contentData, setContentData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchOfficeLocation = async () => {
            try {
                const response = await AxiosInstance.get(`api/privacypolicy/`);
                if (response.data) {
                    setContentData(response.data);
                } else {
                    setError(true);
                }
            } catch (err) {
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        fetchOfficeLocation();
    }, []);

    if (error) {
        return <NotFound />;
    }

    return (
        <>
            {loading ?
                <Loading /> :
                <>
                    <section id="privacy-section" style={{ paddingTop: '64px' }}>
                        <Container sx={{ py: { xs: 2, sm: 4 } }}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Typography component="h2" variant="h4" align="center">
                                        Privacy Policy
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper elevation={0}>
                                        <Grid sx={{my: 1}}>
                                            <Typography component="h5" variant="h5" sx={{mb: 1}}>
                                                1. Background and contact details
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    '& p': {
                                                        margin: 0, // Remove margin from p tags
                                                        padding: 0, // Remove padding from p tags
                                                    },
                                                }}
                                                dangerouslySetInnerHTML={{ __html: `${contentData.contact_detail}` }}
                                            />
                                        </Grid>
                                        <Grid sx={{my: 2}}>
                                            <Typography component="h5" variant="h5" sx={{mb: 1}}>
                                                2. Processing of personal data
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    '& p': {
                                                        margin: 0, // Remove margin from p tags
                                                        padding: 0, // Remove padding from p tags
                                                    },
                                                }}
                                                dangerouslySetInnerHTML={{ __html: `${contentData.personal_data_process}` }}
                                            />
                                        </Grid>
                                        <Grid sx={{my: 2}}>
                                            <Typography component="h5" variant="h5" sx={{mb: 1}}>
                                                3. Security
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    '& p': {
                                                        margin: 0, // Remove margin from p tags
                                                        padding: 0, // Remove padding from p tags
                                                    },
                                                }}
                                                dangerouslySetInnerHTML={{ __html: `${contentData.security}` }}
                                            />
                                        </Grid>
                                        <Grid sx={{my: 2}}>
                                            <Typography component="h5" variant="h5" sx={{mb: 1}}>
                                                4. Use of cookies
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    '& p': {
                                                        margin: 0, // Remove margin from p tags
                                                        padding: 0, // Remove padding from p tags
                                                    },
                                                }}
                                                dangerouslySetInnerHTML={{ __html: `${contentData.cookies_use}` }}
                                            />
                                        </Grid>
                                        <Grid sx={{my: 2}}>
                                            <Typography component="h5" variant="h5" sx={{mb: 1}}>
                                                5. Your rights
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    '& p': {
                                                        margin: 0, // Remove margin from p tags
                                                        padding: 0, // Remove padding from p tags
                                                    },
                                                }}
                                                dangerouslySetInnerHTML={{ __html: `${contentData.user_right}` }}
                                            />
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Container>
                    </section>
                    <Footer />
                </>
            }
        </>

    );
}

export default PrivacyPolicy
