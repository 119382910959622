import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import AxiosInstance from '../Axios'
import { Container, Grid, Typography, Box, Slide, Paper, Stack, Button, IconButton} from '@mui/material';
import './Page.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Footer from '../Footer/Footer';
import * as Icons from '@mui/icons-material';
import NotFound from '../404/NotFound';
import Loading from '../Misc/Loading';

// Function to dynamically get the icon component
const getIconComponent = (iconName) => {
    const IconComponent = Icons[iconName];
    if (!IconComponent) {
        console.error(`Icon ${iconName} not found`);
        return null;
    }
    return <IconComponent />;
};

// Render Page Banner
const PageBanner = ({ banner, onNext, onPrev, showNavigation }) => {
    const link = banner.to_link.startsWith('/') ? banner.to_link : `/${banner.to_link}`;
    return (
        <Grid item xs={12}>
            <div className="banner-carousel-container">
                <Paper>
                    <div className="page-banner-image" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/banner/${banner.bg_image})` }}>
                        <div className="page-banner-content">
                            <Stack spacing={4} useFlexGap sx={{ width: { xs: '100%', sm: '100%' } }}>
                                <Typography
                                    component="span"
                                    variant="h1"
                                    sx={{
                                        fontSize: 'clamp(3rem, 10vw, 3.5rem)',
                                        alignSelf: 'left',
                                        textAlign: 'left',
                                        textTransform: 'uppercase',
                                        fontWeight: '700',
                                        color: (theme) => (theme.palette.mode === 'light' ? 'primary.main' : 'primary.light'),
                                    }}
                                >
                                    {banner.title}
                                </Typography>
                                <Typography
                                    textAlign="left"
                                    color="text.secondary"
                                    sx={{ alignSelf: 'left', width: { sm: '100%', md: '100%' }, fontSize: '24px', color: (theme) => (theme.palette.mode === 'light' ? '#fff' : 'primary.light') }}
                                >
                                    {banner.description}
                                </Typography>
                                <Stack
                                    direction={{ xs: 'column', sm: 'row' }}
                                    alignSelf="left"
                                    spacing={1}
                                    useFlexGap
                                    sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
                                >
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        component={Link}
                                        to={link}
                                        sx={{ lineHeight: '2.25', textTransform: "uppercase" }}
                                    >
                                        Contact Us
                                    </Button>
                                </Stack>
                            </Stack>
                        </div>
                        <div className="page-nav-icons">
                            {showNavigation && (
                                <>
                                    <IconButton className="prev-button" onClick={onPrev}>
                                        {getIconComponent('ChevronLeft')}
                                    </IconButton>
                                    <IconButton className="next-button" onClick={onNext}>

                                        {getIconComponent('ChevronRight')}
                                    </IconButton>
                                </>
                            )}
                        </div>
                    </div>
                </Paper>
            </div>
        </Grid>
    );
};
const PageItem = ({ itemKey, content }) => {
    const { type, feature_image, title, description, options } = content;
    const index = itemKey
    return (
        <Grid
            key={index}
            container
            spacing={2}
            sx={{ py: { xs: 2, sm: 4 } }}
        >
            <Grid item xs={12} md={6} order={{ xs:2, sm: 1, md: index % 2 === 0 ? 1 : 2 }}>
                <Slide direction={index % 2 === 0 ? 'right' : 'left'} in={true} timeout={800}>
                    <Box
                        component="div"
                        sx={{
                            alignSelf: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            overflow: 'hidden',
                            borderRadius: '10px',
                            outline: '1px solid',
                            outlineColor: 'rgba(191, 204, 217, 0.5)',
                            boxShadow: '0 0 12px 8px rgba(156, 204, 252, 0.2)',
                        }}
                    >
                        <img
                            src={`${process.env.PUBLIC_URL}/assets/images/services/${feature_image}`}
                            alt={title}
                            style={{ maxWidth: '100%', height: 'auto' }}
                        />
                    </Box>
                </Slide>
            </Grid>
            <Grid item xs={12} md={6} order={{xm:1, sm: 2, md: index % 2 === 0 ? 2 : 1 }} sx={{ alignSelf: 'top' }}>
                <Typography variant="body2" color="text.primary" sx={{ mb: 0.5, fontSize: { xs: 12, sm: 14, textTransform: 'uppercase', fontWeight: '700' } }}>
                    {type}
                </Typography>
                <Box sx={{ height: 'fit-content', width: '100%', background: 'none' }}>
                    <Typography variant="body2" sx={{ fontSize: { xs: 24, sm: 36 }, mb: 0.5 }}>
                        {title}
                    </Typography>
                    <Typography
                        color="text.secondary"
                        variant="body2"
                        sx={{
                            '& p': {
                                margin: 0, // Remove margin from p tags
                                padding: 0, // Remove padding from p tags
                            },
                            fontSize: { xs: 14, sm: 16 }, textAlign: 'justify'
                        }}
                        dangerouslySetInnerHTML={{ __html: description }}
                    />
                    {options && (
                        <Grid>
                            {options.map((option, optIndex) => (
                                <OptionPageItem key={optIndex} itemKey={optIndex} content={option} />
                            ))}
                        </Grid>
                    )}
                </Box>
            </Grid>
        </Grid>
    );
};

const OptionPageItem = ({ itemKey, content }) => {
    const { title, description } = content;

    return (
        <Grid sx={{ padding: '10px 0' }}>
            <Typography component="h4" sx={{ fontWeight: '700' }}>
                {title}
            </Typography>
            <Typography
                color="text.secondary"
                variant="body2"
                sx={{
                    '& p': {
                        my: 1,
                        padding: 0, // Remove padding from p tags
                    },
                    fontSize: { xs: 14, sm: 16 }, textAlign: 'justify'
                }}
                dangerouslySetInnerHTML={{ __html: description }}
            >
            </Typography>
        </Grid>
    );
};

const BenefitCard = ({ itemKey, content }) => {
    const { type, title, description, benefits } = content;
    const index = itemKey

    return (
        <Container sx={{ mb: 2 }} key={index}>
            <Typography variant="body2" color="text.primary" sx={{ mb: 1, fontSize: { xs: 12, sm: 14, textTransform: 'uppercase', fontWeight: '700' } }}>
                {type}
            </Typography>
            <Box sx={{ height: 'fit-content', width: '100%', background: 'none' }}>
                <Typography variant="body2" sx={{ mb: 0.5, fontSize: { xs: 24, sm: 36 } }}>
                    {title}
                </Typography>
                <Typography
                    color="text.secondary"
                    variant="body2"
                    sx={{
                        '& p': {
                            margin: 0, // Remove margin from p tags
                            padding: 0, // Remove padding from p tags
                        },
                        fontSize: { xs: 14, sm: 16 }, textAlign: 'justify'
                    }}
                    dangerouslySetInnerHTML={{ __html: description.replace(/\n/g, '<br/>') }}
                >
                </Typography>
            </Box>
            <Grid container spacing={2} sx={{ py: { xs: 1, sm: 2 } }}>
                {benefits && benefits.map((benefit, index) => {
                    return (
                        <Grid item xs={12} sm={6} md={4} key={index} style={{ display: 'flex' }}>
                            <Card style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        paddingTop: '8px'
                                    }}
                                >
                                    {getIconComponent(benefit.feature_icon) && React.cloneElement(getIconComponent(benefit.feature_icon), {
                                        sx: { fontSize: 80, color: '#1976d2' }
                                    })}
                                </Box>
                                <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {benefit.title}
                                    </Typography>
                                    <Typography
                                        className="benefit-desc"
                                        color="text.secondary"
                                        variant="body2"
                                        sx={{
                                            '& p': {
                                                margin: 0, // Remove margin from p tags
                                                padding: 0, // Remove padding from p tags
                                            }
                                        }}
                                        dangerouslySetInnerHTML={{ __html: benefit.description }}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>);
                })}
            </Grid>
        </Container>
    );
};

const extractDataByPrefix = (data, prefix) => {
    const serviceDetails = data?.service_details?.[0];
    if (!serviceDetails) return [];

    const extractedData = Object.keys(serviceDetails)
        .filter(key => key.startsWith(prefix))
        .reduce((acc, key) => {
            const newKey = key.replace(prefix, ''); // Remove the prefix
            acc[newKey] = serviceDetails[key];
            return acc;
        }, {});

    // Add the type key with the formatted prefix value
    extractedData.type = prefix.replace(/_/g, ' ').trim();

    return [extractedData]; // Wrap the object in an array
};

const ServicePage = () => {
    const { serviceUrl } = useParams();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [banners, setBanners] = useState([]);
    const [contexts, setContext] = useState([]);
    const [objectives, setObjectives] = useState([]);
    const [businessBenefits, setBusinessBenefits] = useState([]);
    const [serviceOptions, setServiceOptions] = useState([]);

    useEffect(() => {
        const fetchServiceDetail = async () => {
            try {
                const response = await AxiosInstance.get(`api/service/${serviceUrl}`);
                if (response.data) {
                    setBanners(extractDataByPrefix(response.data, 'banner_'));
                    setContext(extractDataByPrefix(response.data, 'context_'));
                    setObjectives(extractDataByPrefix(response.data, 'objective_'));
                    setBusinessBenefits(extractDataByPrefix(response.data, 'business_benefits_'));
                    setServiceOptions(extractDataByPrefix(response.data, 'service_option_'));
                } else {
                    setError(true);
                }
            } catch (err) {
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        fetchServiceDetail();
    }, [serviceUrl]);

    useEffect(() => {
        if (!loading && !error && banners.length > 0) {
            const intervalId = setInterval(() => {
                setCurrentSlide((prevSlide) => (prevSlide === banners.length - 1 ? 0 : prevSlide + 1));
            }, 5000); // Change slide every 5000ms (5 seconds)

            return () => clearInterval(intervalId);
        }
    }, [loading, error, banners]);

    if (error) {
        return <NotFound />;
    }

    return (
        <>
            {loading ?
                <Loading /> :
                <Grid>
                    <Grid container spacing={2} alignItems="center">
                        {banners.length > 0 && (
                            <PageBanner
                                banner={banners[currentSlide]}
                                onNext={() => setCurrentSlide((prevSlide) => (prevSlide === banners.length - 1 ? 0 : prevSlide + 1))}
                                onPrev={() => setCurrentSlide((prevSlide) => (prevSlide === 0 ? banners.length - 1 : prevSlide - 1))}
                                showNavigation={banners.length > 1}
                            />
                        )}
                    </Grid>
                    <section id="page-section" style={{ paddingTop: '64px' }}>
                        <Container sx={{ mb: 2 }}>
                            <Grid container id="context">
                                {contexts.length > 0 && contexts.map((content, index) => (
                                    <PageItem key={index} itemKey={index} content={content} />
                                ))}
                            </Grid>
                            <Grid container id="objective">
                                {objectives.length > 0 && objectives.map((content, index) => (
                                    <PageItem key={1} itemKey={1} content={content} />
                                ))}
                            </Grid>
                        </Container>
                        <Grid id="business-benefit" sx={{ py: { xs: 2, sm: 2 }, backgroundColor: '#f0f8ffb3' }}>
                            {businessBenefits.length > 0 && businessBenefits.map((benefit, index) => (
                                <BenefitCard
                                    key={index}
                                    itemKey={index}
                                    content={benefit}
                                />
                            ))}
                        </Grid>
                        <Container sx={{ mb: 2 }}>
                            <Grid container id="service-options">
                                {serviceOptions.length > 0 && serviceOptions.map((content, index) => (
                                    <PageItem key={1} itemKey={1} content={content} />
                                ))}
                            </Grid>
                        </Container>
                    </section>
                    <Footer />
                </Grid>
            }
        </>
    );
};

export default ServicePage;