import React from 'react';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress'
import { Container } from '@mui/material';

function Loading() {
    return (
        <Container sx={{
            py: {
                xs: 4, sm: 10, display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                mx: 'auto'
            }
        }}>
            <Stack sx={{ color: 'grey.500' }} spacing={2} direction="row">
                <CircularProgress />
                <CircularProgress color="success" />
                <CircularProgress color="inherit" />
            </Stack>
        </Container>
    );
}

export default Loading;