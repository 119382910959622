import * as React from 'react';
import PropTypes from 'prop-types';

import { Routes, Route } from 'react-router-dom';
import './App.css';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import getTheme from './getTheme';

import NavBar from './components/NavBar/NavBar';
import Home from './components/Home/Home';
import About from './components/About/About';
import Services from './components/Services/Services';
import ServicePage from './components/Page/ServicePage';
import Contact from './components/Contact/Contact';
import NotFound from './components/404/NotFound';
import PrivacyPolicy from './components/Page/PrivacyPolicy';
import ServiceTerm from './components/Page/ServiceTerm';
import Unsubscribe from './components/Page/Unsubscribe';
import CookieConsentDialog from './components/Misc/Cookie';

function ToggleCustomTheme({ showCustomTheme, toggleCustomTheme }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100dvw',
        position: 'fixed',
        bottom: 24,
      }}
    />
  );
}
ToggleCustomTheme.propTypes = {
  showCustomTheme: PropTypes.shape({
    valueOf: PropTypes.func.isRequired,
  }).isRequired,
  toggleCustomTheme: PropTypes.func.isRequired,
};
function App() {
  const [mode, setMode] = React.useState('light');
  const [showCustomTheme] = React.useState(false);
  const SSCtheme = createTheme(getTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };
  
  return (
    <ThemeProvider theme={showCustomTheme ? SSCtheme : defaultTheme}>
      <CssBaseline />
      <div sx={{ bgcolor: 'background.default' }}>
      <NavBar mode={mode} toggleColorMode={toggleColorMode} />
        <Routes>
          <Route path="" element={<Home />} />
          <Route path="Home" element={<Home />} />
          <Route path="About" element={<About />} />
          <Route path="Services" element={<Services />} />
          <Route path="Service" element={<Services />} />
          <Route path="/services/:serviceUrl" element={<ServicePage />} />
          <Route path="Contact" element={<Contact />} />
          <Route path="Privacy-Policy" element={<PrivacyPolicy />} />
          <Route path="Terms-Of-Use" element={<ServiceTerm />} />
          <Route path="Unsubscribe" element={<Unsubscribe />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <CookieConsentDialog />
      </div>
    </ThemeProvider>
  );
}

export default App;
