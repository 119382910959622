import { React, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import AxiosInstance from '../Axios'
import { Container, Grid, Card, CardContent, Typography, Link } from '@mui/material';
import './Services.css';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { Avatar } from '@mui/material';
import Footer from '../Footer/Footer';
import Loading from '../Misc/Loading';


const BoxItem = ({ content }) => {
  const { name, url, front_description, front_image, back_description, back_bg_color } = content;
  return (
    <Grid item xs={12} md={4} className="box-item">
      <Grid className="flip-box">
        <Card className="flip-box-front text-center" sx={{ backgroundImage: `url(/assets/images/services/${front_image})` }}>
          <CardContent className="inner">
            <Typography variant="h5" component="h3" className="flip-box-header" sx={{ fontWeight: '500' }}>
              {name}
            </Typography>
            <Typography
              className='flip-box-p'
              variant="body1"
              sx={{ pt: 2 }}
              dangerouslySetInnerHTML={{ __html: front_description }}
            />
            <Grid container justifyContent="flex-end" sx={{ position: 'fixed', bottom: '15px', right: '15px' }}>
              <Avatar
                sx={{
                  backgroundColor: 'primary.main',
                  width: 50,
                  height: 50,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '50%',
                }} className="flip-box-img"
              >
                <ChevronRightRoundedIcon
                  sx={{
                    color: 'common.white',
                    fontSize: 32,
                  }}
                />
              </Avatar>
            </Grid>
          </CardContent>
        </Card>
        <Card className="flip-box-back text-center" sx={{ backgroundColor: `${back_bg_color}` }}>
          <CardContent className="inner">
            <Typography variant="h5" component="h5">
              {name}
            </Typography>
            <Typography
              variant="body1"
              sx={{ pt: 2, fontSize: '18px !important' }}
              dangerouslySetInnerHTML={{ __html: back_description }}
            />
            <Link
              component={RouterLink}
              variant="contained"
              sx={{
                position: 'fixed', bottom: '15px', right: '15px',
                color: 'common.white',
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '10px 20px',
                borderRadius: '4px',
                textDecoration: 'none',
                border: '1px solid #fff',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                transition: 'background-color 0.3s ease',
                '&:hover': {
                  backgroundColor: 'common.white',
                  color: 'primary.main',
                },
              }}
              to={`/services/${url}`}
            >
              Learn More <ChevronRightRoundedIcon sx={{ marginLeft: '4px' }} />
            </Link>
          </CardContent>
        </Card>
      </Grid>
    </Grid >
  );
};

const Services = () => {
  const [serviceData, setServiceData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = () => {
      AxiosInstance.get(`api/service`, {
        params: {
          is_active: 'true'
        }
      }).then((res) => {
        setServiceData(res.data);
      }).finally(() => {
        setLoading(false);
      });
    };

    getData();
  }, []);

  return (
    <>
      <section id="services-section" style={{ paddingTop: '56px' }}>
        {loading ?
          <Loading /> :
          <Container sx={{ py: { xs: 2, sm: 4 } }} >
            <Grid item xs={12}>
              <Typography component="h2" variant="h4">
                Explore Services
              </Typography>
              <Typography variant="body1">
                Empower your cloud journey with our cutting-edge services. Explore our offerings to build a secure and sustainable cloud environment, staying ahead of evolving cyber threats.
              </Typography>
            </Grid>
            <Grid container spacing={3} className="box-container">
              {serviceData && serviceData.map((content, index) => (
                <BoxItem key={index} content={content} />
              ))}
            </Grid>
          </Container>
        }
      </section>
      <Footer />
    </>
  );
};

export default Services;